<template>
  <div class="payment-container">
    <!-- Google Pay Button -->
    <div class="payment-method text-left w-full block mt-2" ref="googlePayButtonContainer"></div>
    <div v-if="!isGooglePayAvailable" class="text-gray-600 text-left">
      Google Pay is not available on this device
    </div>
    <!-- Apple Pay Button -->
    <div class="payment-method text-left">
      <apple-pay-button
        v-if="isApplePayAvailable"
        buttonstyle="black"
        type="plain"
        locale="en-US"
        @click="startApplePay"
      ></apple-pay-button>
      <div v-else class="text-gray-600">Apple Pay is not available on this device</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import { usePlanStore } from '@/stores/planStore'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const store = usePlanStore()
    const router = useRouter()
    const isGooglePayAvailable = ref(false)
    const isApplePayAvailable = ref(false) // Local reactive variable

    onMounted(() => {
      if (typeof window.google === 'undefined') {
        window.addEventListener('onGooglePayLoaded', () => {
          initializeGooglePay()
        })
      } else {
        initializeGooglePay()
      }
    })

    watch(
      () => store.isApplePayAvailable,
      (newValue) => {
        isApplePayAvailable.value = newValue // Update local variable when store changes
      },
      { immediate: true }
    )

    const initializeGooglePay = () => {
      const paymentsClient = new google.payments.api.PaymentsClient({ environment: 'TEST' })

      // Dynamically create the Google Pay button
      const button = paymentsClient.createButton({
        onClick: startGooglePay,
        buttonColor: 'default', // Change to 'black' or 'white' as needed
        buttonType: 'pay' // Supported values: 'buy', 'plain', 'pay', etc.
      })

      // Append the button to the container
      const googlePayButtonContainer = document.querySelector('.payment-method')
      if (googlePayButtonContainer) {
        googlePayButtonContainer.appendChild(button)
      }

      paymentsClient
        .isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['VISA', 'MASTERCARD', 'AMEX']
              }
            }
          ]
        })
        .then((response) => {
          isGooglePayAvailable.value = response.result
        })
        .catch((err) => {
          console.error('Google Pay availability error:', err)
        })
    }

    const startApplePay = () => {
      store.startApplePaySession(router) // Pass the signup ID dynamically
    }

    const startGooglePay = async () => {
      const paymentsClient = new google.payments.api.PaymentsClient({ environment: 'TEST' })
      const paymentDataRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['VISA', 'MASTERCARD', 'AMEX']
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'checkoutltd', // Replace with your payment processor
                gatewayMerchantId: 'pk_sbox_rto2f5l2go4vfg5xcpqoi56cimb'
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: 'pk_sbox_rto2f5l2go4vfg5xcpqoi56cimb',
          merchantName: 'ViewQwest eStore'
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: '1.00',
          currencyCode: 'SGD',
          countryCode: 'SG'
        }
      }

      try {
        const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest)
        if (paymentData.paymentMethodData && paymentData.paymentMethodData.tokenizationData) {
          const tokenStr = paymentData.paymentMethodData.tokenizationData.token
          const token = JSON.parse(tokenStr)

          const result = await store.processPayment(store.signupId, token, 'google_pay')

          Swal.fire({
            icon: result.success ? 'success' : 'error',
            title: result.success ? 'Payment Completed' : 'Payment Failed',
            text: result.message || 'There was an issue processing your payment. Please try again.',
            confirmButtonText: 'OK',
            allowOutsideClick: false
          }).then(async () => {
            if (result.success) {
              await store.pushToVendors()
              router.push({ name: 'success-welcome-to-viewqwest' })
            }
          })
        } else {
          throw new Error('Tokenization data is missing in paymentData')
        }
      } catch (err) {
        console.error('Google Pay payment error:', err)
      }
    }

    return {
      isApplePayAvailable,
      isGooglePayAvailable,
      startGooglePay,
      startApplePay
    }
  }
})
</script>

<style scoped>
.payment-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.payment-method {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

apple-pay-button,
.gpay-button {
  width: 240px;
  border-radius: 4px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gpay-button {
  background-color: #4285f4;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gpay-button:hover {
  background-color: #357ae8;
}

.gpay-button:active {
  background-color: #2c69dd;
}

.gpay-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(66, 133, 244, 0.4);
}

.text-gray-600 {
  color: #718096;
  font-size: 14px;
}

div {
  margin-top: 16px; /* Space between the elements */
}
</style>
