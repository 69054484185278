import './assets/main.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'floating-vue/dist/style.css'
import FloatingVue from 'floating-vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

// main.ts
import 'flatpickr/dist/flatpickr.css';


const app = createApp(App)
app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      autoHide: true,
      preventOverflow: true
    }
  }
})
// Register 'apple-pay-button' as a custom element globally
app.config.compilerOptions.isCustomElement = (tag) => tag === 'apple-pay-button';
app.use(createPinia())
app.use(router)

app.mount('#app')
