// Utility to save data in localStorage
export const saveToLocalStorage = (key: string, data: any) => {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem(key, serializedData);
      console.log(`Data saved under key "${key}" to localStorage.`);
    } catch (error) {
      console.error(`Error saving data to localStorage under key "${key}":`, error);
    }
  };
  
  // Utility to load data from localStorage
  export const loadFromLocalStorage = (key: string): any | null => {
    try {
      const serializedData = localStorage.getItem(key);
      if (serializedData) {
        const data = JSON.parse(serializedData);
        console.log(`Data loaded from localStorage under key "${key}":`, data);
        return data;
      } else {
        console.log(`No data found in localStorage under key "${key}".`);
        return null;
      }
    } catch (error) {
      console.error(`Error loading data from localStorage under key "${key}":`, error);
      return null;
    }
  };
  
  // Utility to save data with expiration time
  export const saveToLocalStorageWithExpiry = (key: string, data: any, ttl: number) => {
    const expiry = Date.now() + ttl; // ttl in milliseconds
    const dataWithExpiry = { ...data, expiry };
    saveToLocalStorage(key, dataWithExpiry);
  };
  
  // Utility to load data with expiration handling
  export const loadFromLocalStorageWithExpiry = (key: string): any | null => {
    const storedData = loadFromLocalStorage(key);
    if (!storedData) return null;
  
    const { expiry } = storedData;
    if (Date.now() > expiry) {
      // Data has expired
      console.log(`Data under key "${key}" has expired. Removing from localStorage.`);
      localStorage.clear();
      return null;
    }
    return storedData;
  };
  
  // Utility to remove data from localStorage
  export const removeFromLocalStorage = (key: string) => {
    try {
      localStorage.clear();
      console.log(`Data removed from localStorage under key "${key}".`);
    } catch (error) {
      console.error(`Error removing data from localStorage under key "${key}":`, error);
    }
  };
  
  // Example: Save temp data without expiration
  export const saveTempData = (temp_data: any) => {
    saveToLocalStorage('temp_data', temp_data);
  };
  
  // Example: Load temp data
  export const loadTempData = () => {
    return loadFromLocalStorage('temp_data');
  };
  
  // Example: Save temp data with expiration (1 hour)
  export const saveTempDataWithExpiry = (temp_data: any) => {
    const oneHourInMillis = 3600 * 1000;
    saveToLocalStorageWithExpiry('temp_data', temp_data, oneHourInMillis);
  };
  
  // Example: Load temp data with expiration handling
  export const loadTempDataWithExpiry = () => {
    return loadFromLocalStorageWithExpiry('temp_data');
  };
  