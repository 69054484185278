import { defineStore } from 'pinia'
import api, { type PromoPlanResponse, type SavePaymentData } from '@/services/api'
import type { SignupData } from '@/interface/interfaces'
import { loadTempDataWithExpiry } from '@/utils/Utils'
import Swal from 'sweetalert2'
import moment from 'moment'

const ERROR = 'ERROR'

// Define interfaces for the data structure
// Define the structure for dynamic plans
export interface Plan {
  id: string
  name: string
  price: number
  description?: string
  isSelected?: boolean
  plan_type: string
  installation: any
  installation_default: any
}

interface PlanResponse {
  data: {
    bundle_count: number
    plan_count: number
    plans: Record<string, Plan[]> // Dynamic keys for plan categories
  }
}

interface PromoResponse {
  plans: Record<string, Plan[]> // Dynamic plans fetched from promo
  voucher: {
    voucher_description: string
  }
}

const data = {
  status: 'OK',
  uinfin: {
    source: '',
    value: ''
  },
  name: {
    source: '',
    value: ''
  },
  sex: {
    source: '',
    value: ''
  },
  race: {
    source: '',
    value: ''
  },
  nationality: {
    source: '',
    value: ''
  },
  dob: {
    source: '',
    value: ''
  },
  email: {
    source: '',
    value: ''
  },
  mobileno: {
    source: '',
    value: ''
  },
  add: {
    country: {
      code: '',
      desc: ''
    },
    unit: {
      value: ''
    },
    street: {
      value: ''
    },
    lastupdated: '',
    block: {
      value: ''
    },
    source: '',
    postal: {
      value: ''
    },
    classification: '',
    type: '',
    floor: {
      value: ''
    },
    building: {
      value: ''
    }
  }
}

export const usePlanStore = defineStore('planStore', {
  state: () => ({
    plans: {} as Record<string, Plan[]>, // Dynamic plans for different categories
    selectedPlan: {
      plan: null as Plan | null,
      finalPrice: '',
      selectedEnhancements: [] as any[], // Adjust types as needed
      selectedRouter: {} as any,
      selectedHardwareKey: ''
    },
    defaultTab: '' as string | null, // Default tab stored as a string (category name)
    disableTab: false as boolean,
    disable_manual_signup: false as boolean,
    signupId: localStorage.getItem('res_osp_res_id') || null,
    idHash: localStorage.getItem('res_osp_res_id_hash') || null,
    promoCode: null as string | null,
    isNextBtn: false as boolean,
    user_device: null as string | null,
    hasStaticIP: false as boolean,
    planUpdated: false as boolean,
    optInMarketing: false,
    loading: false as boolean,
    hasPaymentTerms: false,
    vendorStatus: {
      myInfo: false,
      gothereSg: false
    },
    Myinfo_URL: null as string | null,
    personData: data,
    promoter: null as any,
    postalDetails: null as any,
    unitDetails: null as any,
    isPostalLoading: false as boolean,
    isUnitLoading: false as boolean,
    vopsDetails: {} as any,
    summaryDetails: {} as any,
    previousSignupData: null as SignupData | null,
    vops_type_default: '0126F000001Id0r',
    vops_type: '0126F000001Id0r',
    vopsDefaultValue: true,
    activationDateCondition: false,
    billingActivationDateCondition: false,
    defaultActivationDateCondition: false,
    hostedPageLoaded: false,
    isPayment: false,
    paymentResult: null,
    description: null,
    isApplePayAvailable: false,
    goto_welcome: false,
    zId: '',
    // Default url
    defualtUrl: import.meta.env.VITE_ORIGIN,
    coverage_area: '',
    building_type: '',
    dateTimeAvailability: [] as String[],
    disclaimerHomePassed: '',
    marketing_coms: false,
    // Andre enable postal code if error backend
    forceEnablePostalCode: true as boolean,
    // Andre still continue if the postal code timeout
    isTimeOutPostalCode: false as boolean,
    formSubmitted: false as boolean
  }),

  actions: {
    async processPayment(signupId: number, paymentData: any, payment_method: string) {
      try {
        const response = await api.processApplePayPayment(signupId, paymentData, payment_method) // Reuse API method or adjust if necessary
        console.log('Payment processing response:', response.data)

        // Check if payment was successful
        if (response.data.approved && response.data.status === 'Authorized') {
          console.log('Payment authorized. Proceeding with capture...')

          // Capture the payment (if necessary, you might need another API call here)
          // const captureResponse = await api.capturePayment(response.data.id);
          // console.log('Capture response:', captureResponse.data);

          return {
            success: true,
            message: 'Your payment has been processed successfully.'
          }
        } else {
          return {
            success: false,
            message: 'Payment was not approved. Consider voiding the authorization.'
          }
        }
      } catch (error) {
        console.error('Error processing payment:', error)
        return {
          success: false,
          message: 'Payment processing failed.'
        }
      }
    },
    // Fetch all available plans
    async fetchDefaultPlans() {
      this.loading = true
      try {
        const response = await api.fetchPlans()
        const planData: PlanResponse = response.data

        // Initialize an empty object for plans
        const plans: Record<string, Plan[]> = {}
        console.log('plans: ', planData.data) // Loop through each key in the planData and check if it's an array before adding to the plans
        Object.keys(planData.data).forEach((category) => {
          const planList = planData.data[category]
          if (Array.isArray(planList)) {
            plans[category] = planList // Only add the category if the value is an array
          }
        })

        // Update the store state with the filtered plans
        this.plans = plans
        console.log('fetchDefaultPlans: ', planData)
      } catch (error) {
        console.error('Error fetching plans:', error)
      } finally {
        this.loading = false
      }
    },

    // Apply promo code and fetch corresponding plans
    async fetchPromoPlans(promoCode: string) {
      if (promoCode) {
        // Generate signup ID if it doesn't exist
        if (!this.signupId || !this.idHash) {
          await this.generateSignupId()
        }

        this.loading = true
        try {
          if (this.signupId && this.idHash) {
            const response = await api.applyPromo(this.signupId, promoCode, this.idHash)
            const promoData: PromoResponse = response.data

            // Store promo description and fetched plans
            this.promoCode = promoCode
            this.description = promoData?.data.voucher?.voucher_description
            console.log('promoData: ', promoData)
            // Fetch the plans for the signup ID and update dynamically
            await this.fetchPlansBySignup(this.signupId)
          }
        } catch (error) {
          this.promoCode = ''
          Swal.fire({
            icon: 'error',
            title: 'Promo Code Error',
            text:
              error?.response?.data?.message ||
              'Failed to apply the promo code. The page will reload now.',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.href = this.defualtUrl
          })

          console.error('Error applying promo or fetching plans:', error)
        } finally {
          this.promoCode = ''
          this.loading = false
        }
      }
    },
    async generateSignupId() {
      const payload: SignupData = {
        origin: window.location.href,
        user_device: this.user_device || 'unknown device',
        __target_form: 'search'
      }

      try {
        const response = await api.postSignupData(payload)
        const result = response.data.data.signup
        this.signupId = result.id
        this.idHash = result.id_hash
        this.saveSignupData(this.signupId, this.idHash)
      } catch (error) {
        console.error('Error generating signup ID:', error)
      }
    },

    // Fetch plans based on promo code or default
    async fetchPromoOrDefaultPlans(promoCode: string | undefined) {
      if (promoCode) {
        await this.fetchPromoPlans(promoCode)
      } else {
        await this.fetchDefaultPlans()
      }
    },
    // Fetch plans based on signup ID
    async fetchPlansBySignup(signupId: string) {
      this.loading = true
      try {
        const response = await api.fetchPlansBySignup(signupId)
        const planData: PromoPlanResponse = response.data

        // Initialize an empty object for plans
        const plans: Record<string, Plan[]> = {}

        // Loop through each key in the planData and check if it's an array before adding to the plans
        Object.keys(planData.data.plans).forEach((category) => {
          const planList = planData.data.plans[category]
          if (Array.isArray(planList)) {
            plans[category] = planList // Only add the category if the value is an array
          }
        })

        // Update default tab and disable state from response
        this.defaultTab = planData.data.default_tab
        this.disableTab = planData.data.disable_tab
        this.disable_manual_signup = planData?.data?.disable_manual_signup ?? false

        console.log('disable_manual_signup: ', this.disable_manual_signup)
        // Update the plans dynamically in the store
        this.plans = plans

        console.log('fetchPlansBySignup: ', planData)
      } catch (error) {
        console.error('Error fetching plans by signup ID:', error)
      } finally {
        this.loading = false
      }
    },

    // Check the status of vendor services like MyInfo or Gothere
    async checkVendorStatus(vendorName: string) {
      try {
        const response = await api.checkVendorStatus(vendorName)
        if (vendorName === 'MyInfo') {
          this.vendorStatus.myInfo = response.data.status
        } else if (vendorName === 'gothere-sg') {
          this.vendorStatus.gothereSg = response.data.status
        }
      } catch (error) {
        console.error(`Error checking ${vendorName} status:`, error)
      }
    },

    // Fetch user device info
    async fetchUserDevice() {
      try {
        const response = await api.fetchUserDevice()
        this.user_device = response.data.data.device
      } catch (error) {
        console.error('Error fetching user device:', error)
      }
    },

    // Select a specific plan
    selectPlan(plan: any) {
      this.selectedPlan = plan
    },

    // Validate and submit signup data
    async submitSignupData(data: SignupData) {
      try {
        if (JSON.stringify(this.previousSignupData) === JSON.stringify(data)) {
          console.log('No changes in data, skipping submission.')
          return
        }
        this.loading = true
        const response = await api.postSignupData(data)
        const result = response.data.data
        // After successful signup, call the MyInfo API
        const uid = result.signup.id_hash
        const signupId = result.signup.id
        this.signupId = signupId
        this.idHash = uid
        if (this.signupId && this.idHash) {
          this.saveSignupData(this.signupId, this.idHash)
        }
        const token = import.meta.env.VITE_MYINFO_TOKEN
        if (!this.Myinfo_URL) {
          await this.submitToMyInfoAPI(uid, signupId, token)
        }
        this.previousSignupData = data // Save the last submitted signup data
        if (data['__target_form']) {
          this.formSubmitted = true
        }
      } catch (error: any) {
        console.error('Error submitting signup response:')
        Swal.fire({
          title: 'Something went wrong!',
          text: error?.response?.data?.message || 'Please try again.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } finally {
        this.loading = false
      }
    },

    // Fetch order summary data
    async fetchSummary() {
      if (!this.signupId || !this.idHash) return
      this.loading = true
      try {
        const response = await api.fetchSummary(this.signupId, this.idHash)
        this.summaryDetails = response.data
        return response.data
      } catch (error) {
        console.error('Error fetching summary:', error)
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: 'Please try again.',
          confirmButtonText: 'OK'
        }).then(() => {
          window.location.href = import.meta.env.VITE_ORIGIN
        })
      } finally {
        this.loading = false
      }
    },

    // Call MyInfo API after successful signup data submission
    async submitToMyInfoAPI(uid: string, signupId: string, token: string) {
      if (!signupId || !token || !uid) {
        return
      }
      try {
        const response = await api.fetchMyInfoData(uid, signupId, token)
        this.Myinfo_URL = response.data.Myinfo_URL ?? null
      } catch (error) {
        console.error('Error calling MyInfo API:', error)
      }
    },

    // Fetch postal code details
    async fetchPostal(postalCode: string) {
      try {
        const response = await api.fetchPostal(postalCode)
        return response.data
      } catch (error) {
        console.error('Error fetching postal code details:', error)
      }
    },

    async payment_method_id(id: string, autoPay = false) {
      if (!this.signupId || !this.idHash) {
        console.error('Signup ID or Hash is missing')
        return
      }

      const paymentData = {
        signup_id: this.signupId,
        payment_method_id: id,
        signup_id_hash: this.idHash,
        __target_form: 'payment',
        autoPay: autoPay
      }

      try {
        // Replace 'postData' with your actual method to send POST requests
        await api.postSignupData(paymentData)
        await this.pushToVendors() // Call the vendorPush function after successful submission
        console.log('Payment method submitted successfully.')
      } catch (error) {
        console.error('Error submitting payment method:', error)
      }
    },

    // Push data to vendors after signup
    async pushToVendors() {
      if (!this.signupId || !this.idHash) return
      this.loading = true
      try {
        const response = await api.pushToVendors(this.signupId, this.idHash)
        return response.data
      } catch (error) {
        console.error('Error pushing data to vendors:', error)
      } finally {
        this.loading = false
      }
    },

    // Save the signup data in local storage
    saveSignupData(signupId: string, idHash: string) {
      this.signupId = signupId
      this.idHash = idHash
      localStorage.setItem('res_osp_res_id', signupId)
      localStorage.setItem('res_osp_res_id_hash', idHash)
    },

    // Set plan as updated
    setPlanUpdated(value: boolean) {
      this.planUpdated = value
    },

    // Set the next button state
    setNextBtn(value: boolean) {
      this.isNextBtn = value
    },

    // Fetch postal code details
    async fetchPostalDetails(postalCode: string) {
      this.isPostalLoading = true
      try {
        const response = await api.fetchPostal(postalCode)
        const { data } = response.data
        if (data) {
          this.vopsDetails = data
          this.disclaimerHomePassed = data?.disclaimerHomePassed
          this.dateTimeAvailability = Object.keys(data?.dateTimeAvailability).map(
            (dateStr) => moment(new Date(dateStr)).format('YYYY-MM-DD') // Convert using native Date constructor
          ) as String[]

          this.postalDetails = data?.postalcode
        }
      } catch (error) {
        console.error('Error fetching postal code details:', error)
      } finally {
        this.isPostalLoading = false
      }
    },

    // Action to set the VOPS type and manage default value flag
    updateVopsType(newType: string) {
      if (newType) {
        this.vops_type = newType
        this.vopsDefaultValue = false
      } else {
        this.vops_type = this.vops_type_default
        this.vopsDefaultValue = true
      }
    },

    // Example action for updating activation date conditions
    setActivationConditions(status: boolean) {
      this.activationDateCondition = status
      this.billingActivationDateCondition = status
      this.defaultActivationDateCondition = status
    },

    // Fetch unit details based on postal code and unit number
    async fetchUnitDetails(postalCode: string, unitNumber: string) {
      this.isUnitLoading = true
      try {
        const response = await api.fetchUnitDetails(postalCode, unitNumber)
        const { data } = response.data
        console.log('fetchUnitDetails: ', data)

        if (data) {
          // Update VOPS type using the caseType from the response
          const caseType = data?.caseType || this.vops_type_default
          this.updateVopsType(caseType)
          this.coverage_area = data?.coverageStatus
          this.building_type = data?.buildingType
          // Set activation date conditions based on the response
          const activationDateCondition = data?.activationDateCondition || false
          this.setActivationConditions(activationDateCondition)

          // Assign other details
          this.vopsDetails = data
          this.disclaimerHomePassed = data?.disclaimerHomePassed
          this.dateTimeAvailability = Object.keys(data?.dateTimeAvailability).map(
            (dateStr) => moment(new Date(dateStr)).format('YYYY-MM-DD') // Convert using native Date constructor
          ) as String[]

          this.unitDetails = data?.postalcode
        }
      } catch (error) {
        console.error('Error fetching unit details:', error)
      } finally {
        this.isUnitLoading = false
      }
    },
    // New action to fetch person data
    async fetchPersonData(code: string, uid: string, token: string) {
      try {
        this.loading = true
        const response = await api.fetchPersonDataByCode(code, uid, token)

        if (this.signupId && this.idHash) {
          // Now load temp_data from localStorage with expiry handling
          const storedTempData = loadTempDataWithExpiry()

          // If data exists and hasn't expired, replace the entire state
          if (storedTempData) {
            console.log('Loaded temp_data from localStorage:', storedTempData)

            // Replace the entire state with the stored data
            this.$patch({
              isNextBtn: storedTempData.isNextBtn,
              marketing_coms: storedTempData.marketing_coms,
              plans: storedTempData.plans || {
                gamer_lifestyle: [],
                home_network: [],
                home_wifi: []
              },
              selectedPlan: storedTempData.selectedPlan || {
                plan: null,
                finalPrice: '',
                selectedEnhancements: [],
                selectedRouter: {},
                selectedHardwareKey: ''
              },
              defaultTab: storedTempData.defaultTab || null,
              disableTab: storedTempData.disableTab || false,
              disable_manual_signup: storedTempData.disable_manual_signup || false,
              signupId: storedTempData.signupId || null,
              idHash: storedTempData.idHash || null,
              promoCode: storedTempData.promoCode || null,
              user_device: storedTempData.user_device || null,
              hasStaticIP: storedTempData.hasStaticIP || false,
              planUpdated: storedTempData.planUpdated || false,
              loading: storedTempData.loading || false,
              promoter: storedTempData.promoter || null,
              vendorStatus: storedTempData.vendorStatus || {
                myInfo: false,
                gothereSg: false
              },
              Myinfo_URL: storedTempData.Myinfo_URL || null,
              personData: storedTempData.personData || {},
              postalDetails: storedTempData.postalDetails || null,
              unitDetails: storedTempData.unitDetails || null,
              vopsDetails: storedTempData.vopsDetails || {},
              summaryDetails: storedTempData.summaryDetails || null,
              vops_type_default: storedTempData.vops_type_default || '0126F000001Id0r',
              vops_type: storedTempData.vops_type || '0126F000001Id0r',
              vopsDefaultValue: storedTempData.vopsDefaultValue || true,
              activationDateCondition: storedTempData.activationDateCondition || false,
              billingActivationDateCondition:
                storedTempData.billingActivationDateCondition || false,
              defaultActivationDateCondition:
                storedTempData.defaultActivationDateCondition || false,
              hostedPageLoaded: storedTempData.hostedPageLoaded || false,
              isPayment: storedTempData.isPayment || false,
              paymentResult: storedTempData.paymentResult || null
            })
          } else {
            console.log('No valid temp_data found in localStorage.')
          }
        }

        if (response?.data?.status === ERROR && import.meta.env.VITE_production === 'yes') {
          return window.open(import.meta.env.VITE_ORIGIN, '_self')
        }

        // Process the fetched person data
        if (response.data) {
          this.personData = response.data // Store the returned person data
          console.log('Person data fetched successfully:', this.personData)
        }
      } catch (error) {
        console.error('Error fetching person data:', error)
        return window.open(import.meta.env.VITE_ORIGIN, '_self')
      } finally {
        this.loading = false
      }
    },
    // Clear postal and unit details (useful when resetting the form)
    clearPostalAndUnitDetails() {
      this.postalDetails = null
      this.unitDetails = null
    },

    // Zuora Payment Initialization
    async generatePayment(field_account_id?: string) {
      // Check if the hosted page is already loaded to prevent re-initializing
      if (this.hostedPageLoaded) return
      try {
        // Show loader (manually handling display logic without jQuery)
        const paymentPreLoader = document.getElementById('paymentPreLoader')
        const zuoraPayment = document.getElementById('zuora_payment')

        if (paymentPreLoader && zuoraPayment) {
          paymentPreLoader.style.display = 'block'
          zuoraPayment.style.display = 'none'
        }

        // Simulate loader delay of 3 seconds before showing Zuora payment
        setTimeout(() => {
          if (zuoraPayment && paymentPreLoader) {
            zuoraPayment.style.display = 'block'
            paymentPreLoader.style.display = 'none'
          }
          this.hostedPageLoaded = true
        }, 3000)

        // For non-production environments, create a mock payment button for development purposes
        const isPayButton = document.getElementById('payButton')
        if (
          import.meta.env.VITE_production === 'no' &&
          import.meta.env.VITE_payment_method_id &&
          !isPayButton
        ) {
          const paymentDetails = document.getElementById('paymentDetails')
          if (paymentDetails) {
            const payButton = document.createElement('button')
            payButton.id = 'payButton'
            payButton.classList.add('btn', 'btn-primary')
            payButton.textContent = 'FOR DEVS ONLY'
            paymentDetails.appendChild(payButton)

            // Add click event to simulate payment for development purposes
            payButton.onclick = () => {
              if (import.meta.env.VITE_payment_method_id) {
                // this.payment_method_id(import.meta.env.VITE_payment_method_id)
                return
              }
            }
          }
        }

        // Define the callback function to handle the payment response
        const callback = (paymentResponse: any) => {
          console.log('Zuora Payment Response:', paymentResponse)
          this.setPaymentResult(paymentResponse)

          // Log Zuora events using TLT if available
          if (window.TLT) {
            window.TLT.logCustomEvent('Zuora', {
              description: 'Zuora Response',
              value: paymentResponse
            })
          }
        }

        // Get payment page ID from environment variables and debug log it
        const paymentPageId = import.meta.env.VITE_MAIN_PAYMENT_ID
        console.log('Zuora Payment Page ID:', paymentPageId)

        if (!paymentPageId) {
          throw new Error('Payment Page ID is missing in environment variables.')
        }

        // Prepare form data for API call to get the Zuora signature
        const formData = {
          env: import.meta.env.VITE_ENV,
          hosted_page: paymentPageId,
          signup_id: this.signupId
        }

        console.log('Form Data for Zuora Signature:', formData)

        // API call to get Zuora signature
        const response = await api.getZuoraSignature(formData)

        // Debug: Log Zuora signature response
        console.log('Zuora Signature Response:', response)

        if (!response.data || !response.data.signature || !response.data.token) {
          throw new Error('Failed to retrieve Zuora signature or token.')
        }

        const signature = response.data

        if (!signature.key) {
          throw new Error('Zuora signature key is missing.')
        }

        // Prepare parameters for rendering the Zuora iframe

        const params = {
          tenantId: signature.tenantId,
          id: paymentPageId,
          token: signature.token,
          signature: signature.signature,
          style: 'inline',
          key: signature.key,
          submitEnabled: 'true',
          locale: 'fr_FR',
          url: import.meta.env.VITE_hostedPage,
          currency: 'SGD',
          paymentGateway: import.meta.env.VITE_paymentGateway
        }

        // If the field_account_id is provided, include it in the parameters
        if (field_account_id) {
          params['field_accountId'] = field_account_id
        }

        // Debug: Log parameters to be passed to Zuora render function
        console.log('Zuora Payment Render Params:', params)

        // Render the Zuora payment form
        window.Z.render(params, { creditCardCountry: 'SGP' }, callback)

        // Set agreement terms for Zuora
        window.Z.setAgreement(
          'External',
          'Recurring',
          'Visa Mastercard Amex',
          'https://viewqwest.com/wp-content/uploads/2020/07/VQ-Specific-Terms-and-Conditions-Broadband-Residential-v200817.pdf'
        )
      } catch (error) {
        // Handle errors gracefully and log them for debugging
        console.error('Error generating Zuora payment:', error)
      }
    },
    // Save Zuora Payment Result
    setPaymentResult(payment: any) {
      if (payment.success === 'true' && payment.AuthorizeResult) {
        console.log('payment nadre:', payment)
        this.paymentResult = payment
        this.saveBilling(payment)
      } else {
        console.error('Payment failed:', payment)
      }
    },

    // Save Billing after Payment
    async saveBilling(payment: any) {
      try {
        const paymentData: SavePaymentData = {
          signup_id: this.signupId,
          cc_payment: payment?.refId,
          payment_method_id: payment?.refId,
          creditCardAddress1: payment?.creditCardAddress1,
          creditCardCountry: payment?.creditCardCountry,
          creditCardCity: payment?.creditCardCity,
          creditCardState: payment?.creditCardState,
          creditCardPostalCode: payment?.creditCardPostalCode,
          email: payment?.email,
          phone: payment?.phone,
          __target_form: 'payment',
          autoPay: 'true',
          signup_id_hash: this.idHash
        }

        if (!payment.refId) {
          // Andre
          Swal.fire({
            icon: 'error',
            title: 'Billing Error',
            html: 'Unable to complete the billing process. Please try again. If the error persists, please try another card or contact the customer service at <a href="mailto:cs@viewqwest.com" style="color: #3085d6; text-decoration: underline;">cs@viewqwest.com</a>.',
            footer:
              '<button id="refreshPageButton" class="swal2-confirm swal2-styled">Try Again</button>',
            showConfirmButton: false, // Removes the default "OK" button
            didRender: () => {
              // Add a click event listener to the custom "Try Again" button
              const refreshButton = document.getElementById('refreshPageButton')
              if (refreshButton) {
                refreshButton.addEventListener('click', () => {
                  location.reload() // Refresh the page
                })
              }
            }
          })
          return
          // return this.payment_method_id(import.meta.env.VITE_payment_method_id)
        }

        if (this.zId) {
          const payload = {
            account_key: this.zId,
            default_payment: payment?.refId,
            signup_id: this.signupId
          }
          await api.savePayment(payload)
          await api.postSignupData(paymentData)
          await this.pushToVendors()
          this.goto_welcome = true
        } else {
          await api.postSignupData(paymentData)
          await this.pushToVendors()
          this.goto_welcome = true
        }
      } catch (error) {
        console.error('Error saving billing info:', error)
      }
    },
    // New action for updating the previous ISP
    async updatePrevISPForm(previousISP: string) {
      if (!this.signupId || !this.idHash) {
        console.error('Signup ID or hash is missing')
        return
      }

      const prevISPData = {
        signup_id: this.signupId,
        signup_id_hash: this.idHash,
        previous_isp: previousISP
      }

      try {
        const response = await api.updatePrevISPForm(prevISPData)
        if (response.status === 200) {
          console.log('Previous ISP updated successfully')
          return true
        } else {
          console.error('Failed to update previous ISP')
          return false
        }
      } catch (error) {
        console.error('Error updating previous ISP:', error)
        return false
      }
    },

    async getSignup() {
      const storedTempData = loadTempDataWithExpiry()
      try {
        const response = await api.fetchSignupDataLayer(
          storedTempData?.signupId,
          storedTempData?.idHash
        )

        // console.log('Response:', response)
        // console.log('storedTempData:', storedTempData)
        // console.log('storedTempData?.selectedPlan?.selectedRouter?.zuora_push_price:', storedTempData?.selectedPlan?.selectedRouter?.zuora_push_price)

        // Accessing the signup data
        const signupData = response?.data?.data?.signup

        // console.log('Signup Data:', signupData)
        // console.log('Stored Temp SignupId:', storedTempData?.signupId)

        // Push signup data and signupId to the data layer
        this.pushDataLayer(
          signupData,
          storedTempData?.signupId,
          storedTempData?.selectedPlan?.selectedRouter?.zuora_push_price
        )
      } catch (error) {
        console.error('Error getting signup:', error)
      }
    },

    pushDataLayer(signupData: any, orderId: number, zuoraPushPrice: string) {
      // console.log('signupData pushDataLayer', signupData)
      // console.log('orderId pushDataLayer', orderId)

      const { meta_data } = signupData

      // console.log('meta_data', meta_data?.account?.['account.plan_price'])
      // console.log(
      //   'meta_data.account.account.plan_price',
      //   meta_data?.account?.['account.person.name.full']
      // )

      // console.log('zuoraPushPrice', zuoraPushPrice)

      const plan_price = meta_data?.account?.['account.plan_price'] || 0
      const reference = meta_data?.account?.['account.person.name.full'] || ''
      const amount = plan_price != 0 ? zuoraPushPrice : 0

      // console.log('plan_price dataLayer:', plan_price)
      // console.log('reference to dataLayer:', reference)
      // console.log('amount to dataLayer:', amount)

      window.dataLayer = window.dataLayer || []

      const data = {
        amount,
        reference,
        Order: Number(orderId)
      }

      console.log('Before Pushed data to dataLayer:', data)

      window.dataLayer.push(data)

      console.log('After Pushed data to dataLayer:', data)

      localStorage.clear()
    }
  },

  getters: {
    // Get selected plan
    getSelectedPlan: (state) => state.selectedPlan,

    // Get available plans
    getPlans: (state) => state.plans,

    // Get loading state
    isLoading: (state) => state.loading,

    // Get postal details
    getPostalDetails: (state) => state.postalDetails,

    // Get unit details
    getUnitDetails: (state) => state.unitDetails,

    // Get loading states for postal and unit details
    getIsPostalLoading: (state) => state.isPostalLoading,
    getIsUnitLoading: (state) => state.isUnitLoading
  }
})
