export function nricValidator(nricInput: string): boolean {
  // Remove any non-alphanumeric characters and trim spaces
  nricInput = nricInput
    .replace(/[^0-9a-zA-Z]+/g, '')
    .trim()
    .toUpperCase()

  // Check for 'VQ' NRIC special case
  const first = nricInput.charAt(0)
  const last = nricInput.charAt(nricInput.length - 1)
  if (first === 'V' && last === 'Q') {
    return true
  }

  // Prepare array for weighted calculation
  const icArray = new Array(9)
  for (let i = 0; i < 9; i++) {
    icArray[i] = nricInput.charAt(i)
  }

  // Weights for each digit
  const weights = [0, 2, 7, 6, 5, 4, 3, 2]

  // Compute weighted sum
  let weightSum = 0
  for (let i = 1; i < 8; i++) {
    weightSum += parseInt(icArray[i], 10) * weights[i]
  }

  // Determine offset based on first character
  let offset = 0
  if (icArray[0] === 'T' || icArray[0] === 'G') {
    offset = 4
  } else if (icArray[0] === 'M') {
    offset = 3
  }

  // Compute the remainder
  let remainder = (offset + weightSum) % 11
  if (icArray[0] === 'M') {
    remainder = 10 - remainder
  }

  // Define valid letters for different first letters
  const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A']
  const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K']
  const m = ['K', 'L', 'J', 'N', 'P', 'Q', 'R', 'T', 'U', 'W', 'X']

  // Determine the correct letter for validation
  let checkLetter: string
  if (icArray[0] === 'S' || icArray[0] === 'T') {
    checkLetter = st[remainder]
  } else if (icArray[0] === 'F' || icArray[0] === 'G') {
    checkLetter = fg[remainder]
  } else if (icArray[0] === 'M') {
    checkLetter = m[remainder]
  } else {
    return false // Invalid NRIC type
  }

  // Return whether the last character matches the calculated check letter
  return icArray[8] === checkLetter
}
