<template>
  <footer class="bg-white">
    <div
      class="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between p-4 space-y-2 sm:space-y-0"
    >
      <!-- Left side: Copyright text -->
      <p class="text-gray-600 text-sm text-center sm:text-left">
        © {{ currentYear }} ViewQwest Pte Ltd. All rights reserved.
      </p>

      <!-- Right side: Links -->
      <div class="flex flex-col sm:flex-row sm:space-x-4 text-center">
        <a
          href="https://viewqwest.com/sg/wp-content/uploads/sites/4/2024/01/VQ-General-TC-Residential-v200709.pdf"
          class="text-gray-600 hover:underline mb-2 sm:mb-0"
          >Terms & Conditions</a
        >
        <a href="https://viewqwest.com/sg/data-protection/" class="text-gray-600 hover:underline"
          >Privacy Policy</a
        >
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
export default {
  name: 'FooterComponent',
  data() {
    return {
      currentYear: new Date().getFullYear() // Dynamically set the current year
    }
  }
}
</script>
