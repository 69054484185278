<template>
  <div class="w-full bg-white flex flex-col items-center py-12">
    <h2 class="text-2xl md:text-2xl font-bold text-center mb-6">
      Awesome plan! Now, tell us more about you so we can proceed with your subscription.
    </h2>

    <div class="text-center max-w-lg mx-auto">
      <p class="text-blue-600 text-lg font-semibold mb-2">Sign up easily with Myinfo!</p>
      <p class="text-gray-600 text-base md:text-lg mb-6">
        Just log in with your SingPass ID to have your information instantly filled & verified. No
        need to upload your NRIC or FIN!
      </p>

      <img src="@/assets/singpass_logo.png" alt="Singpass" class="w-40 mx-auto mb-4" />

      <!-- Button to retrieve MyInfo URL -->
      <button
        class="bg-red-600 text-white font-bold py-3 px-8 rounded hover:bg-red-700 transition duration-300 mb-4"
        @click="retrieveMyInfo"
        :disabled="!myInfoEnabled"
        :class="{ 'cursor-not-allowed opacity-50': !myInfoEnabled }"
      >
        Retrieve Myinfo with Singpass
      </button>

      <div v-if="myInfoEnabled">
        <a
          href="https://www.developer.tech.gov.sg/products/categories/digital-identity/myinfo/overview.html"
          class="text-blue-600 text-sm underline block mb-6"
        >
          Find out more about Myinfo
        </a>
      </div>

      <div v-else class="mb-2">
        <p class="text-red-600 text-sm md:text-base font-bold text-center">
          MyInfo is currently unavailable.<br />
          In the meantime, please sign up manually.<br />
          Thank you!
        </p>
      </div>
      <p
        data-test="signup-manually"
        v-if="!manualDisabled"
        class="text-gray-600 text-sm md:text-base"
      >
        Don’t have a SingPass ID? No problem!
        <a href="#" class="text-blue-600 underline" @click.prevent="showManualSignup"
          >Sign up here</a
        >
        manually.
      </p>
    </div>

    <p class="text-gray-500 text-xs mt-10 text-center">
      All information provided will be used for registration purposes and will be kept confidential.
      For more information, please refer to our
      <a href="https://viewqwest.com/sg/data-protection/" class="text-blue-600 underline"
        >Data Protection Policy</a
      >.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { usePlanStore } from '@/stores/planStore' // Import your store

export default defineComponent({
  name: 'SingpassComponent',
  setup(_, { emit }) {
    const store = usePlanStore() // Access the store

    // Computed property to check if MyInfo service is enabled
    const myInfoEnabled = computed(() => store.vendorStatus.myInfo)
    const manualDisabled = computed(() => store.disable_manual_signup)

    const retrieveMyInfo = () => {
      const myInfoUrl = store.Myinfo_URL // Access Myinfo_URL from the store
      if (myInfoUrl) {
        window.open(myInfoUrl, '_self')
      } else {
        console.error('Myinfo_URL is not available in the store')
      }
    }

    // Emit event to show manual signup form
    const showManualSignup = () => {
      emit('show-manual-signup')
    }

    return {
      retrieveMyInfo,
      showManualSignup,
      myInfoEnabled,
      manualDisabled
    }
  }
})
</script>
