h1
<template>
  <div class="w-full max-w-screen-xl lg:px-8 mx-auto my-10 p-6 bg-white rounded-lg">
    <!-- Customer Information Heading -->
    <h3 class="text-2xl text-left">Enter your Customer Information</h3>
    <div class="border-t-2 border-gray-300 my-6"></div>

    <!-- Customer Information Form -->
    <VeeForm
      @submit="onSubmit"
      @invalid-submit="onInvalidSubmit"
      :validation-schema="validationSchema"
      class="space-y-8"
    >
      <!-- Nationality and NRIC/FIN Fields -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="nationality" class="text-left text-sm font-medium text-gray-900"
            >Nationality <span class="text-red-500">*</span></label
          >
          <Field
            as="select"
            id="nationality"
            v-model="formValues.nationality"
            :disabled="!!store.personData?.nationality?.value"
            name="nationality"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm"
          >
            <option v-for="(nationality, index) in nationalities" :key="index" :value="nationality">
              {{ nationality }}
            </option>
          </Field>
          <ErrorMessage name="nationality" class="text-red-500 text-sm text-left" />
        </div>
        <div class="flex flex-col">
          <label for="nric" class="text-left text-sm font-medium text-gray-900"
            >NRIC/FIN <span class="text-red-500">*</span></label
          >
          <Field
            data-test="nric"
            as="input"
            type="text"
            name="nric"
            v-model="formValues.id_number"
            :disabled="!!store.personData?.uinfin?.value"
            id="nric"
            value=""
            class="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm uppercase"
          />

          <ErrorMessage name="nric" class="text-red-500 text-sm text-left" />
        </div>
      </div>

      <!-- Full Name and Mobile Number Fields -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="flex flex-col md:col-span-2">
          <label for="full-name" class="text-left text-sm font-medium text-gray-900"
            >Full Name <span class="text-red-500">*</span>
            <InformationCircleIcon
              tabindex="0"
              v-tooltip="{
                content: 'Surname, First Name, Middle Name if applicable',
                theme: 'info-tooltip',
                triggers: ['click']
              }"
              class="text-gray-500 cursor-pointer h-5 w-5 inline-block"
          /></label>
          <Field
            data-test="fullName"
            as="input"
            type="text"
            name="fullName"
            id="full-name"
            v-model="formValues.fullName"
            :disabled="!!store.personData?.name?.value"
            value=""
            class="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm"
          />

          <ErrorMessage name="fullName" class="text-red-500 text-sm text-left" />
        </div>
      </div>

      <!-- Mobile Number and Email Fields -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="mobile" class="text-left text-sm font-medium text-gray-900"
            >Mobile Number <span class="text-red-500">*</span></label
          >
          <Field
            as="input"
            type="tel"
            name="mobile"
            data-test="mobile"
            id="mobile"
            v-model="formValues.phone"
            :disabled="!!store.personData?.mobileno?.value"
            placeholder="12345678"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
          />
          <ErrorMessage name="mobile" class="text-red-500 text-sm text-left" />
        </div>
        <div class="flex flex-col">
          <label for="email" class="text-left text-sm font-medium text-gray-900"
            >Email <span class="text-red-500">*</span></label
          >
          <Field
            as="input"
            type="email"
            data-test="email"
            name="email"
            id="email"
            v-model="formValues.email"
            :disabled="!!store.personData?.email?.value"
            placeholder="example@email.com"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
          />
          <ErrorMessage name="email" class="text-red-500 text-sm text-left" />
        </div>
      </div>

      <!-- Date of Birth and Gender Fields -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="flex flex-col">
          <label for="dob" class="text-left text-sm font-medium text-gray-900">
            Date of Birth <span class="text-red-500">*</span>
          </label>
          <VueDatePicker
            v-model="dob"
            text-input
            hide-input-icon
            data-test="dob"
            :enable-time-picker="false"
            :clearable="false"
            model-type="yyyy-MM-dd"
            :disabled="isDatePickerDisabled"
            format="dd-MM-yyyy"
            @update:model-value="onDobChange"
          >
            <template #dp-input="{ value }">
              <div class="relative w-full">
                <Field name="dob" type="text" :modelValue="value" v-slot="{ field, errors }">
                  <input
                    type="text"
                    v-bind="field"
                    class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm pl-10"
                    :class="{ 'is-invalid': !!errors.length }"
                    placeholder="Date of Birth"
                    autocomplete="off"
                  />
                </Field>
                <!-- Calendar Icon -->
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <CalendarIcon class="w-5 h-5 text-gray-400" />
                </div>
              </div>
            </template>
          </VueDatePicker>
          <ErrorMessage name="dob" class="text-red-500 text-sm text-left" />
        </div>

        <div class="flex flex-col">
          <label class="text-left text-sm font-medium text-gray-900"
            >Gender <span class="text-red-500">*</span></label
          >
          <div class="mt-1 flex items-center space-x-4">
            <Field
              as="input"
              id="male"
              data-test="gender"
              name="gender"
              v-model="formValues.gender"
              :disabled="!!store.personData?.sex?.value"
              type="radio"
              value="male"
              class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
            />
            <label for="male" class="ml-2 text-sm font-medium text-gray-900">Male</label>

            <Field
              as="input"
              id="female"
              name="gender"
              v-model="formValues.gender"
              :disabled="!!store.personData?.sex?.value"
              type="radio"
              value="female"
              class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
            />
            <label for="female" class="ml-2 text-sm font-medium text-gray-900">Female</label>
          </div>
          <ErrorMessage name="gender" class="text-red-500 text-sm text-left" />
        </div>
      </div>

      <!-- Customer Postal Code and Unit Number Fields -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Postal Code Field -->
        <div class="flex flex-col relative">
          <label for="postal-code" class="text-left text-sm font-medium text-gray-900"
            >Postal Code <span class="text-red-500">*</span></label
          >
          <Field
            as="input"
            type="text"
            name="postalCode"
            data-test="postalCode"
            id="postal-code"
            v-model="postalCode"
            :disabled="
              store.getIsPostalLoading ||
              (!!store.personData?.add?.postal?.value && !store.forceEnablePostalCode)
            "
            placeholder="123456"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
          />
          <ErrorMessage name="postalCode" class="text-red-500 text-sm text-left" />
          <div v-if="store.getIsPostalLoading" class="absolute right-4 top-10 flex items-center">
            <div class="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-400"></div>
          </div>
        </div>

        <!-- Unit Number Field -->
        <div class="flex flex-col relative">
          <label for="unit-number" class="text-left text-sm font-medium text-gray-900"
            >Unit Number <span class="text-red-500">*</span>
            <InformationCircleIcon
              tabindex="0"
              v-tooltip="{
                html: true,
                content:
                  '<ul><li>For High Rise Building,00-000</li><li>For landed property, 01-00</li></ul>',
                theme: 'info-tooltip',
                triggers: ['click']
              }"
              class="text-gray-500 cursor-pointer h-5 w-5 inline-block"
          /></label>
          <Field
            as="input"
            type="text"
            name="unitNumber"
            data-test="unitNumber"
            id="unit-number"
            v-model="unitNumber"
            :disabled="
              store.getIsUnitLoading ||
              (disableUnitNumber &&
                store.personData?.add?.unit?.value &&
                store.personData?.add?.unit?.value !== 'NA' &&
                store.personData?.add?.unit?.value !== '' &&
                !store.forceEnablePostalCode)
            "
            placeholder="08-707"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
          />
          <ErrorMessage name="unitNumber" class="text-red-500 text-sm text-left" />
          <div v-if="store.getIsUnitLoading" class="absolute right-4 top-10 flex items-center">
            <div class="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-400"></div>
          </div>
        </div>
      </div>

      <!-- Customer Street Name -->
      <div class="flex flex-col mt-4">
        <label for="street-name" class="text-left text-sm font-medium text-gray-900"
          >Street Name <span class="text-red-500">*</span></label
        >
        <!-- <Field
          as="input"
          type="text"
          name="streetName"
          id="street-name"
          v-model="streetName"
          :disabled="
            store.vendorStatus.gothereSg &&
            (isVops || store.getIsUnitLoading || store.personData?.add?.street?.value)
          "
          :value="store.getPostalDetails?.streetName || ''"
          class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
        /> -->
        <Field
          as="input"
          type="text"
          name="streetName"
          id="street-name"
          data-test="streetName"
          v-model="streetName"
          :disabled="
            store.vendorStatus.gothereSg &&
            (isVops || store.getIsUnitLoading || !!store.personData?.add?.street?.value) &&
            !store.forceEnablePostalCode
          "
          :value="store.getPostalDetails?.streetName || ''"
          class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
        />

        <ErrorMessage
          v-if="!store.getIsUnitLoading && !store.getIsPostalLoading"
          name="streetName"
          class="text-red-500 text-sm text-left"
        />
        <!-- Show loading message if fetching street details -->
        <div
          class="flex items-center space-x-2 my-2"
          v-if="store.getIsUnitLoading || store.getIsPostalLoading"
        >
          <span class="text-red-700 text-sm font-medium">
            Please wait while we are verifying your Street Name
          </span>
          <div class="animate-spin rounded-full h-5 w-5 border-t-2 border-primary-color"></div>
        </div>
      </div>

      <!-- Installation Address Section -->
      <div class="mt-8">
        <h1 class="text-2xl text-left">
          Tell us where to install & activate the service
          <span class="text-gray-400">(Installation Address)</span>
        </h1>
        <div class="border-t-2 border-gray-300 my-6"></div>
        <div class="flex items-left mb-4">
          <input
            type="checkbox"
            id="sameAsRegistered"
            v-model="sameAsRegistered"
            class="h-4 w-4 text-red-600 border-gray-300 rounded focus:ring-red-500"
          />
          <label for="sameAsRegistered" class="ml-2 text-sm font-medium text-gray-900"
            >Same as registered address</label
          >
        </div>

        <!-- Installation Postal Code and Unit Number Fields -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <!-- Installation Postal Code -->
          <div class="flex flex-col">
            <label for="installationPostalCode" class="text-left text-sm font-medium text-gray-900"
              >Installation Postal Code <span class="text-red-500">*</span></label
            >
            <Field
              as="input"
              type="text"
              name="installationPostalCode"
              id="installationPostalCode"
              :disabled="sameAsRegistered"
              v-model="installationPostalCode"
              placeholder="123456"
              class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
            <ErrorMessage name="installationPostalCode" class="text-red-500 text-sm text-left" />
          </div>

          <!-- Installation Unit Number -->
          <div class="flex flex-col">
            <label for="installationUnitNumber" class="text-left text-sm font-medium text-gray-900"
              >Installation Unit Number <span class="text-red-500">*</span>
              <InformationCircleIcon
                tabindex="0"
                v-tooltip="{
                  html: true,
                  content:
                    '<ul><li>For High Rise Building,00-000</li><li>For landed property, 01-00</li></ul>',
                  theme: 'info-tooltip',
                  triggers: ['click']
                }"
                class="text-gray-500 cursor-pointer h-5 w-5 inline-block"
            /></label>
            <Field
              as="input"
              type="text"
              name="installationUnitNumber"
              id="installationUnitNumber"
              :disabled="sameAsRegistered"
              v-model="installationUnitNumber"
              placeholder="08-707"
              class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
            <ErrorMessage name="installationUnitNumber" class="text-red-500 text-sm text-left" />
          </div>
        </div>

        <!-- Installation Street Name Field -->
        <div class="flex flex-col mt-4">
          <label for="installationStreetName" class="text-left text-sm font-medium text-gray-900"
            >Installation Street Name <span class="text-red-500">*</span></label
          >
          <Field
            as="input"
            type="text"
            name="installationStreetName"
            id="installationStreetName"
            :disabled="sameAsRegistered && store.vendorStatus.gothereSg"
            v-model="installationStreetName"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 rounded-md shadow-sm sm:text-sm"
          />
          <ErrorMessage name="installationStreetName" class="text-red-500 text-sm text-left" />
        </div>
      </div>
      <div
        v-if="store.vopsDetails?.activationDateCondition && allowedDates && allowedDates.length > 0"
      >
        <h1 class="text-2xl text-left">Choose your Netlink Activation Date</h1>
        <div class="border-t-2 border-gray-300 my-6"></div>
        <div class="bg-gray-50 p-4 rounded-lg shadow-sm max-w-lg mt-4 flex items-start">
          <span
            v-if="store?.disclaimerHomePassed"
            v-html="store?.disclaimerHomePassed"
            class="text-xs text-gray-950 leading-relaxed text-left"
          ></span>
        </div>
      </div>
      <!-- Service Activation Date and Time Fields -->
      <div
        v-if="store.vopsDetails?.activationDateCondition && allowedDates && allowedDates.length > 0"
        class="grid grid-cols-1 md:grid-cols-2 gap-8"
      >
        <!-- Service Activation Date Field -->
        <div class="flex flex-col">
          <label for="serviceActivationDate" class="text-left text-sm font-medium text-gray-900">
            Service Activation Date <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <Flatpickr
              class="mt-1 block w-full py-3 px-4 pl-10 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm"
              v-model="serviceActivationDate"
              :config="flatpickrConfig"
            />
            <!-- Calendar Icon -->
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <CalendarIcon class="w-5 h-5 text-gray-400" />
            </div>
          </div>
          <span
            v-if="!serviceActivationDate && serviceActivationDateIsRequired"
            class="text-red-500 text-sm text-left"
          >
            Service activation date is required
          </span>
        </div>

        <!-- Service Activation Time Field -->
        <div
          class="flex flex-col"
          v-if="store.vopsDetails?.activationTimeCondition && availableTimeSlots.length > 0"
        >
          <label for="serviceActivationTime" class="text-left text-sm font-medium text-gray-900">
            Service Activation Time
          </label>
          <Field
            as="select"
            name="serviceActivationTime"
            id="serviceActivationTime"
            class="mt-1 block w-full py-3 px-4 border border-gray-300 bg-white rounded-md shadow-sm sm:text-sm"
          >
            <option disabled value="">Select a time slot</option>
            <option v-for="time in availableTimeSlots" :key="time" :value="time">{{ time }}</option>
          </Field>

          <ErrorMessage name="serviceActivationTime" class="text-red-500 text-sm text-left" />
        </div>
      </div>

      <!-- Delivery or Installation Option -->
      <div class="mt-8">
        <h3 class="text-2xl text-left">Tell us your preferred Delivery Or Installation Option</h3>
        <div class="border-t-2 border-gray-300 my-6"></div>

        <div class="flex items-start space-y-4 flex-col">
          <ErrorMessage name="installationOption" class="text-red-500 text-sm text-left" />

          <!-- Dynamically generate installation options from store -->
          <div v-for="option in installationOptions" :key="option.key" class="flex items-start">
            <Field
              as="input"
              type="radio"
              :id="option.key"
              name="installationOption"
              :value="option.key"
              class="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
              v-model="selectedInstallationOption"
              :checked="selectedInstallationOption === option.key"
            />
            <label :for="option.key" class="ml-2 text-sm font-medium text-gray-900">
              <!-- Render HTML content for the option name -->
              {{ extractText(option.name) }}
              <InformationCircleIcon
                tabindex="0"
                v-tooltip="{
                  html: true,
                  content: extractTooltipText(option.name),
                  theme: 'info-tooltip',
                  triggers: ['click']
                }"
                class="text-gray-500 cursor-pointer h-5 w-5 inline-block ml-2"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="border-t-2 border-gray-300 my-6"></div>
      <div class="mt-8 text-left">
        <!-- Agreement Checkbox -->
        <div class="flex items-start">
          <Field
            as="input"
            type="checkbox"
            id="agreement"
            name="agreement"
            v-model="formValues.agreement"
            :true-value="true"
            :false-value="false"
            class="h-4 w-4 text-red-600 border-gray-300 rounded focus:ring-red-500"
          />
          <label for="agreement" class="ml-2 text-sm font-medium text-gray-900 text-left">
            I agree to the
            <a
              href="https://viewqwest.com/sg/wp-content/uploads/sites/4/2024/01/VQ-General-TC-Residential-v200709.pdf"
              class="text-blue-500 underline"
              target="_blank"
            >
              ViewQwest General Terms and Conditions
            </a>
            and
            <a
              href="https://viewqwest.com/sg/wp-content/uploads/sites/4/2024/03/2024March-vq-specific-terms-and-conditions-residential-broadband.pdf"
              class="text-blue-500 underline"
              target="_blank"
            >
              Specific Terms and Conditions
            </a>
            for the provision of the subscribed services. I also acknowledge that I have read and
            understood the
            <a
              href="https://viewqwest.com/sg/data-protection/"
              class="text-blue-500 underline"
              target="_blank"
            >
              Data Protection Policy </a
            >.
          </label>
        </div>
        <ErrorMessage name="agreement" class="text-red-500 text-sm" />
      </div>

      <!-- Proceed to Checkout Button -->
      <div v-if="!formSubmitted" class="mt-12 flex justify-end">
        <button
          type="submit"
          class="bg-red-600 text-white py-3 px-8 rounded-md shadow hover:bg-red-700 transition duration-300"
        >
          Proceed to Checkout
        </button>
      </div>
    </VeeForm>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { Field, Form as VeeForm, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { usePlanStore } from '@/stores/planStore' // Import the plan store
import { nricValidator } from './shared/nricValidator'
// @ts-ignore
import VueDatePicker from '@vuepic/vue-datepicker'
// @ts-ignore
import debounce from 'lodash.debounce' // Import lodash debounce

import '@vuepic/vue-datepicker/dist/main.css'
import { nationalities } from './shared/nationalities'
import { CalendarIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'

import VueFlatpickr from 'vue-flatpickr-component'

function formatCountryName(country: string): string {
  if (!country) return ''

  return country
    .toLowerCase() // Convert the whole string to lowercase
    .split(' ') // Split the string by spaces into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ') // Join the words back into a single string
}

export default defineComponent({
  name: 'FormComponent',
  components: {
    Field,
    VeeForm,
    ErrorMessage,
    VueDatePicker,
    InformationCircleIcon,
    Flatpickr: VueFlatpickr,
    CalendarIcon
  },
  setup(props, { emit }) {
    const store = usePlanStore()
    const dob = ref<string | null>(null) // Allow dob to be either a Date or null
    const formValues = ref({
      fullName: '',
      nationality: 'Singaporean',
      phone: '',
      email: '',
      id_number: '',
      gender: '',
      password: '',
      billing_postal: '',
      billing_street: '',
      billing_unit: '',
      installation_postal_code: '',
      installation_street: '',
      installation_unit: '',
      agreement: false
    })
    const installationOptions = ref(store.selectedPlan?.plan?.installation || [])
    const selectedInstallationOption = ref(
      store.selectedPlan?.plan?.installation_default?.key || ''
    )

    const postalCode = ref('') // Postal code
    const unitNumber = ref('') // Unit number
    const streetName = ref('') // Street name for registered address
    const buildingNumber = ref('') // Building number field
    // Separate installation fields
    const installationPostalCode = ref('') // Installation postal code
    const installationUnitNumber = ref('') // Installation unit number
    const installationStreetName = ref('') // Installation street name

    const serviceActivationDate = ref<Date | null>(null)
    const availableTimeSlots = ref<string[]>([]) // Time slots for selected date
    const isVops = ref(true)

    const sameAsRegistered = ref(false)

    const postalCodeValue = computed(() => postalCode.value)
    const unitNumberValue = computed(() => unitNumber.value)

    const disableUnitNumber = ref(false)
    const formSubmitted = ref(false)

    const serviceActivationDateIsRequired = ref(false)

    const allowedDates = computed(() => {
      return store.dateTimeAvailability.map((dateString: any) => new Date(dateString))
    })

    // Watch for changes in selected plan and update installation options accordingly
    watch(
      () => store.selectedPlan,
      (newPlan) => {
        if (newPlan) {
          installationOptions.value = newPlan.plan?.installation || []
          selectedInstallationOption.value = newPlan.plan?.installation_default?.key || ''
        }
      },
      { immediate: true }
    )

    watch([postalCodeValue, unitNumberValue], ([newPostalCode, newUnitNumber]) => {
      if (newPostalCode && newUnitNumber && store.vendorStatus?.gothereSg) {
        // Trigger fetchUnitDetailsDebounced only if both postalCode and unitNumber have values
        fetchUnitDetailsDebounced(newPostalCode, newUnitNumber)
      }
    })

    // Fetch the person data from the store when it changes
    watch(
      () => store.personData,
      (newData) => {
        console.log('personData: ', newData?.status)
        if (!newData?.status?.includes('ERROR') && newData?.status) {
          formValues.value.fullName = newData.name?.value || ''
          let nationality = 'Singaporean'
          if (newData?.nationality?.value === 'SINGAPORE CITIZEN') {
            nationality = 'Singaporean'
          } else {
            const nationalityCap =
              newData?.nationality?.value.charAt(0).toUpperCase() +
              newData?.nationality?.value.toLowerCase().slice(1)
            nationality = nationalityCap
          }

          formValues.value.nationality = formatCountryName(nationality) || 'Singaporean'
          formValues.value.phone = newData.mobileno?.value || ''
          formValues.value.email = newData.email?.value || ''
          formValues.value.id_number = newData.uinfin?.value || ''
          formValues.value.gender = newData.sex?.value === 'F' ? 'female' : 'male'
          dob.value = newData.dob.value ?? null
          // Address data
          postalCode.value = newData.add?.postal?.value || ''
          const format_unit = `${newData.add?.floor?.value || ''}-${newData.add?.unit?.value || ''}`
          unitNumber.value = format_unit === '-' ? '' : format_unit
          disableUnitNumber.value = false
          const regex = /^[a-zA-Z0-9]{1,3}-[a-zA-Z0-9]{1,4}$/
          console.log('regex: ', regex.test(unitNumber.value))
          if (regex.test(unitNumber.value)) {
            disableUnitNumber.value = true
          } else {
            disableUnitNumber.value = false
          }
          streetName.value = newData.add?.street?.value || ''
        }
        console.log('disableUnitNumber: ', disableUnitNumber.value)

        console.log('formValues: ', formValues.value)
        console.log('dob: ', dob.value)
      },
      { immediate: true }
    )

    // Function to validate if the user is at least 18 years old
    const isAtLeast18 = (value: string | null): boolean => {
      if (!value) return false

      // Parse the date in dd-MM-yyyy format using moment
      const birthDate = moment(value, 'DD-MM-YYYY')
      if (!birthDate.isValid()) return false // Ensure the date is valid

      // Calculate the age by comparing the birth date with the current date
      const today = moment()
      const age = today.diff(birthDate, 'years')

      return age >= 18 // Return true if the person is 18 or older
    }

    // Reactive validation schema
    const validationSchema = computed(() =>
      yup.object({
        nationality: yup.string().required('Nationality is required'),
        fullName: yup.string().required('Full Name is required'),
        mobile: yup
          .string()
          .matches(/^[0-9]{8,}$/, 'Mobile number must have at least 8 digits')
          .required('Mobile number is required'),
        email: yup.string().email('Invalid email format').required('Email is required'),
        dob: yup
          .string()
          .required('Date of birth is required')
          .test('age', 'You must be at least 18 years old', isAtLeast18)
          .test('valid-date', 'Date of birth must be a valid date', (value) => {
            return moment(value, 'DD-MM-YYYY', true).isValid()
          })
          .nullable(),
        postalCode: yup
          .string()
          .matches(/^[0-9]{6}$/, 'Postal code must be 6 digits')
          .required('Postal code is required'),
        unitNumber: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]{1,3}-[a-zA-Z0-9]{1,4}$/,
            'Please enter a valid Unit Number in the format XX-XXX or XXX-XXX, and it must include a "-"'
          )
          .required('Unit number is required'),
        streetName: yup.string().required('Street name is required'),
        installationPostalCode: yup
          .string()
          .matches(/^[0-9]{6}$/, 'Postal code must be 6 digits')
          .required('Installation postal code is required'),
        installationUnitNumber: yup
          .string()
          .matches(
            /^[a-zA-Z0-9]{1,3}-[a-zA-Z0-9]{1,4}$/,
            'Please enter a valid Unit Number in the format XX-XXX or XXX-XXX, and it must include a "-"'
          )
          .required('Unit number is required'),
        installationStreetName: yup.string().required('Street name is required'),
        installationOption: yup.string().required('Installation option is required'),
        nric: yup
          .string()
          .test('nric', 'Enter a valid NRIC', (value) => nricValidator(value || ''))
          .required('NRIC/Passport is required'),
        gender: yup.string().required('Gender is required'),
        serviceActivationDate: yup.string().nullable(),
        serviceActivationTime: yup.string().nullable(),
        agreement: yup.boolean().oneOf([true], 'You must agree to the terms to proceed')
      })
    )

    // Debounced function for postal code
    const fetchPostalDetailsDebounced = debounce(async (newPostalCode: string) => {
      if (newPostalCode.length === 6) {
        try {
          await store.fetchPostalDetails(newPostalCode) // Fetch postal details

          const placemark =
            store.getPostalDetails?.postalcode?.checkFeasibilityResponseObjectList?.[0] || null
          console.log('fetchPostalDetailsDebounced: ', store.getPostalDetails, placemark)
          // If data exists, populate the form fields
          if (placemark) {
            const buildingNum =
              placemark?.buildingNumber && placemark?.buildingNumber !== 'NA'
                ? `${placemark.buildingNumber} `
                : ''
            streetName.value = `${buildingNum}${placemark?.streetName || ''}`
            // unitNumber.value = placemark?.unitNumber || ''
            isVops.value = true
          } else {
            isVops.value = false
            console.error('No placemark found')
          }
        } catch (error) {
          isVops.value = false
          store.forceEnablePostalCode = false
          console.error('Error fetching postal details:', error)
        }
      }
    }, 2000) // 500ms debounce to limit API calls

    // Debounced function for unit details
    const fetchUnitDetailsDebounced = debounce(
      async (newPostalCode: string, newUnitNumber: stringr) => {
        if (newPostalCode.length === 6 && newUnitNumber) {
          try {
            await store.fetchUnitDetails(newPostalCode, newUnitNumber) // Fetch unit details

            const placemark = store.getUnitDetails?.checkFeasibilityResponseObjectList?.[0] || null
            console.log('fetchUnitDetailsDebounced: ', store.getUnitDetails)

            // If data exists, populate the form fields
            if (placemark) {
              const buildingNum =
                placemark?.buildingNumber && placemark?.buildingNumber !== 'NA'
                  ? `${placemark.buildingNumber} `
                  : ''
              streetName.value = `${buildingNum}${placemark?.streetName || ''}`
              // unitNumber.value = placemark?.unitNumber || ''
              isVops.value = true
            } else {
              isVops.value = false
              console.error('No placemark found')
            }
          } catch (error) {
            isVops.value = false
            console.error('Error fetching unit details:', error)
          }
        } else {
          store.clearPostalAndUnitDetails() // Clear the unit details if input is incomplete
        }
      },
      2000
    ) // 500ms debounce to limit API calls

    // Watch for postal code changes
    watch(postalCode, (newPostalCode) => {
      if (store.vendorStatus?.gothereSg) {
        fetchPostalDetailsDebounced(newPostalCode) // Fetch postal details when postal code changes
      }

      if (sameAsRegistered.value) {
        installationPostalCode.value = newPostalCode // Copy the postal code

        // Also update the street name after the postal details are fetched
        watch(
          () => store.getPostalDetails?.streetName,
          (newStreetName) => {
            installationStreetName.value = newStreetName || ''
          }
        )
      }
    })
    // Watch for unit number changes
    watch([postalCode, unitNumber], ([newPostalCode, newUnitNumber]) => {
      if (store.vendorStatus?.gothereSg) {
        fetchUnitDetailsDebounced(newPostalCode, newUnitNumber)
      }

      // If "Same as registered address" is checked, update installationUnitNumber
      if (sameAsRegistered.value) {
        installationUnitNumber.value = newUnitNumber
      }
    })

    // Watch for street name changes
    watch(streetName, (newStreetName) => {
      // If "Same as registered address" is checked, update installationStreetName
      if (sameAsRegistered.value) {
        installationStreetName.value = newStreetName
      }
    })

    // Watch for changes in street name
    watch(
      () => store.getPostalDetails?.streetName,
      (newStreetName) => {
        // When postal details update and "Same as registered address" is checked, copy the street name
        if (sameAsRegistered.value) {
          installationStreetName.value = newStreetName || ''
        }
      }
    )

    watch(sameAsRegistered, (newValue) => {
      console.log('sameAsRegistered: ', newValue)
      if (newValue) {
        installationPostalCode.value = postalCode.value
        installationUnitNumber.value = unitNumber.value
        installationStreetName.value = streetName.value
      } else {
        installationPostalCode.value = ''
        installationUnitNumber.value = ''
        installationStreetName.value = ''
      }
    })

    // Watch for changes in street name to ensure it's copied when the checkbox is checked
    watch(streetName, (newStreetName) => {
      if (sameAsRegistered.value) {
        installationStreetName.value = newStreetName // Copy the street name
      }
    })

    // Handle service activation date change
    const onServiceActivationDateChange = (selectedDates: Date[], newDate: any) => {
      console.log('Selected date:', newDate) // Log the raw date value passed in
      let dateObject: Date | null = null

      if (store.dateTimeAvailability) {
        console.log('dateTimeAvailability exists:', store.dateTimeAvailability)
      } else {
        console.log('dateTimeAvailability is missing or undefined.')
      }

      if (typeof newDate === 'string') {
        dateObject = new Date(newDate) // Convert string to Date
        console.log('Converted string to Date object:', dateObject)
      } else if (newDate instanceof Date) {
        dateObject = newDate
        console.log('Date object received:', dateObject)
      } else {
        console.log('Invalid date:', newDate)
      }

      if (dateObject) {
        // Convert Date to 'yyyy/MM/dd' format to match the format in dateTimeAvailability
        const dateKey =
          dateObject.getFullYear() +
          '/' +
          String(dateObject.getMonth() + 1).padStart(2, '0') +
          '/' +
          String(dateObject.getDate()).padStart(2, '0')

        console.log('Formatted date (yyyy/MM/dd):', dateKey)

        if (store?.vopsDetails?.dateTimeAvailability[dateKey]) {
          console.log(
            'Available time slots for the selected date:',
            store.dateTimeAvailability[dateKey]
          )
          availableTimeSlots.value = store.vopsDetails?.dateTimeAvailability[dateKey]
        } else {
          console.log('No available time slots for the selected date.')
          availableTimeSlots.value = []
        }
      } else {
        console.log('Clearing available time slots.')
        availableTimeSlots.value = []
      }
    }

    const onDateInput = (value: string) => {
      if (value) {
        dob.value = value
      } else {
        dob.value = null // Handle null date
      }
    }

    function generatePassword(length = 12) {
      const chars =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}:"<>?|[];\',./`~'
      let password = ''
      for (let i = 0; i < length; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return password
    }

    function mapInstallationOption(option: string) {
      // Find the installation option by key from the installationOptions list
      const selectedOption = installationOptions.value.find((opt: any) => opt.key === option)
      return selectedOption ? selectedOption.key : '' // Return the key if found, otherwise empty
    }

    function getSignupId() {
      // For now, return null or get from store or another source
      return store.signupId || null
    }

    function getSignupIdHash() {
      // For now, return null or get from store or another source
      return store.idHash || null
    }

    const isDatePickerDisabled = computed(() => {
      return Boolean(store.personData?.dob?.value)
    })

    // Handle changes from the date picker
    const onDobChange = (selectedDate: string | null) => {
      console.log('onDobChange: ', selectedDate)
      if (selectedDate) {
        // Store the ISO date in VueDatePicker format (yyyy-MM-dd)
        dob.value = selectedDate
      }
    }

    const extractText = (htmlContent: string) => {
      // Create a DOMParser to parse the string as HTML
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlContent, 'text/html')

      // Find the element by its class name and extract the text content
      const tooltipText = doc.querySelector('.di-tooltip')?.childNodes[0]?.textContent || ''

      return tooltipText.trim()
    }

    const extractTooltipText = (htmlContent: string) => {
      // Create a DOMParser to parse the string as HTML
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlContent, 'text/html')

      // Extract the element with the class 'di-tooltiptext'
      const tooltipText = doc.querySelector('.di-tooltiptext')?.outerHTML || ''

      return tooltipText
    }

    const scrollToSection = (sectionId: string) => {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }

    const onInvalidSubmit = ({ values, errors, results }) => {
      scrollToSection('formSection')
    }

    const onSubmit = async (values: any) => {
      if (!serviceActivationDate.value && serviceActivationDateIsRequired.value) {
        return
      }

      const password = generatePassword()
      let vops_type = store.vops_type
      if (store.dateTimeAvailability?.length === 0) {
        vops_type = store.vops_type_default
      }
      const coverage_area = store.coverage_area
      const building_type = store.building_type
      const formData = {
        signup_id: getSignupId(),
        signup_id_hash: getSignupIdHash(),
        billing_country: values.nationality,
        billing_postal: values.postalCode,
        billing_street: values.streetName + ' ' + values.unitNumber,
        billing_unit: values.unitNumber,
        birthday: moment(values.dob, 'DD-MM-YYYY').format('DD/MM/YYYY'),
        building_type: building_type,
        email: values.email,
        full_name: values.fullName,
        gender: values.gender.charAt(0).toUpperCase() + values.gender.slice(1),
        id_number: values.nric,
        installation_postal_code: values.installationPostalCode,
        installation_street: values.installationStreetName + ' ' + values.installationUnitNumber,
        installation_unit: values.installationUnitNumber,
        marketing_coms: null,
        nationality: values.nationality,
        origin: window.location.href,
        password: password,
        phone: values.mobile,
        plan_installation: mapInstallationOption(values.installationOption),
        promoter: null,
        user_device: store.user_device,
        vops_type: vops_type,
        coverage_area: coverage_area,
        __target_form: 'signup',
        ...(serviceActivationDate.value && {
          service_activation_date: moment(serviceActivationDate.value, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          )
        }),
        service_activation_time: values.serviceActivationTime || 'NA'
      }

      console.log('Form submitted with values:', formData)

      emit('form-submitted', formData)
      // Now, we may need to send formData to the backend via an API call, perhaps using axios.

      // For example:
      // axios.post('/api/submit', formData)
      //   .then(response => { /* handle response */ })
      //   .catch(error => { /* handle error */ });
    }

    const flatpickrConfig = ref({
      enable: [],
      dateFormat: 'Y-m-d', // Ensure the date is formatted correctly
      altInput: true,
      altFormat: 'F j, Y', // User-friendly format
      minDate: moment().format('YYYY-MM-DD'),
      onChange: onServiceActivationDateChange
    })

    // Watch the store's dateTimeAvailability property
    watch(
      () => store.formSubmitted,
      (submitted) => {
        formSubmitted.value = submitted
      },
      { immediate: true } // Trigger immediately for initial setup
    )

    watch(
      () => store.activationDateCondition,
      (activationDateCondition) => {
        serviceActivationDateIsRequired.value = activationDateCondition
      },
      { immediate: true } // Trigger immediately for initial setup
    )

    // Watch the store's dateTimeAvailability property
    watch(
      () => store.dateTimeAvailability,
      (newAvailability) => {
        if (newAvailability && newAvailability.length > 0) {
          flatpickrConfig.value.enable = newAvailability

          // Set the minDate to the first enabled date in the array (if available)
          const firstEnabledDate = newAvailability[0]
          if (firstEnabledDate) {
            flatpickrConfig.value.minDate = moment(firstEnabledDate).format('YYYY-MM-DD')
          }
        }
      },
      { immediate: true } // Trigger immediately for initial setup
    )

    return {
      validationSchema,
      onSubmit,
      postalCode,
      formValues,
      unitNumber,
      streetName,
      buildingNumber,
      dob,
      store,
      installationPostalCode,
      installationUnitNumber,
      installationStreetName,
      serviceActivationDate,
      availableTimeSlots,
      onServiceActivationDateChange,
      sameAsRegistered,
      onDateInput,
      isVops,
      installationOptions,
      selectedInstallationOption,
      nationalities,
      onDobChange,
      extractText,
      extractTooltipText,
      isDatePickerDisabled,
      onInvalidSubmit,
      disableUnitNumber,
      allowedDates,
      flatpickrConfig,
      formSubmitted,
      serviceActivationDateIsRequired
    }
  }
})
</script>
<style scoped>
.di-tooltip-container {
  position: relative;
  display: inline-block;
}

.di-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.di-tooltip-container:hover .di-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.di-tooltip-icon {
  margin-left: 5px;
  cursor: pointer;
}
</style>
